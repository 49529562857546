<template>
  <div>
    <div class="vip-type-list" style="font-size:16px">
      <span>{{headerShopName}}-会员</span>
      <div style="display:flex;align-items:center;">
        <div class="date-box">
          <div class="box-left" @click="changeReqYear(false)">＜</div>
          <div>{{reqYear}}</div>
          <div class="box-right" @click="changeReqYear(true)">＞</div>
        </div>
        <el-button type="success" @click="this.$router.go(-1)">返回</el-button>
      </div>
    </div>
    <main  style="margin:0 20px">
      <div class="vip-type-list" style="font-size:16px">
        <span style="font-size:14px">会员卡充值：{{memberRechargeGetMoney.toFixed(2)}}（充值金额：{{realMoney.toFixed(2)}} &nbsp;&nbsp;赠送金额：{{handselMoney.toFixed(2)}} &nbsp;&nbsp;会员卡消费：{{expendMoney.toFixed(2)}}）   </span>
      </div>
      <div style="padding-top:20px">
        <el-table 
          :data="dataOne" 
          border  
          stripe style="width: 100%"
          :header-cell-style="headerStyle"
          :cell-style="cellStyle"
          @cell-click="clickFun"
          :span-method="objectSpanMethod"
        >
          <el-table-column prop="shopName" label="店铺名称" />
          <el-table-column prop="yearMonth" label="时间" />
          <el-table-column prop="newMember" label="新增会员" width="130px" />
          <el-table-column label="会员充值"> 
            <!-- prop="collect"  -->
            <el-table-column prop="memberRechargeGetMoney" label="会员卡充值"  width="120" />
            <el-table-column prop="realMoney" label="充值金额" />
            <el-table-column prop="handselMoney" label="赠送金额" />
            <el-table-column prop="rechargeNumber" label="会员卡充值人数" width="150" />
          </el-table-column>
          <el-table-column label="营业收入">
            <el-table-column prop="expendMoney" label="会员消费" />
            <el-table-column prop="consumeNumber" label="会员卡消费人数" width="150" />
          </el-table-column>
        </el-table>
      </div>
    </main>
    <PrintNoFrom rul='report/yearReportMemberStatistics.json' :add='"&year="+reqYear' />
  </div>
</template>

<script>
import {ref} from 'vue'
import {postData} from"@a"
import {useRouter} from 'vue-router'
export default {
  setup(){
    let headerShopName = window.localStorage.getItem('shopName')
    let reqYear = ref(new Date().getFullYear())
    const router = useRouter()
    const dataOne = ref([
    ])
    const headerStyle = ()=>{
      let style  = {}
      style.color = '#333333'
      style['font-size'] = '14px'
      style['font-weight'] = '400'
      style['padding-left'] = '20px'
      style['background-color'] = '#D7D7D7'
      style['text-align'] = 'center'
      return style
    }
    const cellStyle = ({row, column})=>{
      let style  = {}
      style['text-align'] = 'center'
      style.color = '#000'
      style['font-size'] = '14px'
      style['padding-left'] = '20px'
      style['font-weight'] = '500'
      if(column.label==="时间" && row.shopName !== "合计"){
        style.color = '#169BD5'
        style['text-decoration'] = 'underline'
        style['cursor'] = 'pointer'
      }
      if(column.label==="店铺名称"){
        style['font-weight'] = 600
      }
      return style
    }
    const clickFun = (row, column)=>{
      if(column.label==="时间" && row.shopName !== "合计"){
        router.push({name:'repayment',query:{shopName:row.shopName,shopId:row.shopId,time:row.yearMonth}})
      }
    }
    // 格式化表格店铺名称（合并）
    const objectSpanMethod = ()=>{
    // const objectSpanMethod = ({ row, column, rowIndex, columnIndex })=>{
      // if(window.localStorage.getItem('sss')){
      //   console.log( row, column)
      // }
      // if (columnIndex === 0) {
      //   if (rowIndex % 3 === 0) {
      //     return {
      //       rowspan: 3,
      //       colspan: 1,
      //     }
      //   } else {
      //     return {
      //       rowspan: 0,
      //       colspan: 0,
      //     }
      //   }
      // }
    }
    const changeReqYear = (val)=>{
      if(val && reqYear.value < new Date().getFullYear()){
        ++reqYear.value
        infoData()
      }else if(!val && reqYear.value > new Date().getFullYear()-1){
        --reqYear.value
        infoData()
      }
    }
    // 充值金额：{{memberRechargeGetMoney}}   赠送金额：{{handselMoney}}）    会员卡消费：{{expendMoney}}
    let memberRechargeGetMoney = ref(0)
    let handselMoney = ref(0)
    let expendMoney = ref(0)
    let realMoney = ref(0)
    const infoData = async()=>{
      try{
        let param = {
          year:reqYear.value
        }
        let res = await postData('shop/yearReportMemberStatistics.json',param)
        let lastData = {
          shopName:'合计',
          newMember : 0,
          memberRechargeGetMoney : 0,
          realMoney : 0,
          handselMoney : 0,
          rechargeNumber : 0,
          expendMoney : 0,
          consumeNumber : 0,
          }
        memberRechargeGetMoney.value = 0
        handselMoney.value = 0
        expendMoney.value = 0
        realMoney.value = 0
        for(let i = 0;i<res.data.length;i++){
          lastData.newMember += Number(res.data[i].newMember.toFixed(2))
          lastData.memberRechargeGetMoney += Number(res.data[i].memberRechargeGetMoney.toFixed(2))
          lastData.realMoney += Number(res.data[i].realMoney.toFixed(2))
          lastData.handselMoney += Number(res.data[i].handselMoney.toFixed(2))
          lastData.rechargeNumber += Number(res.data[i].rechargeNumber.toFixed(2))
          lastData.expendMoney += Number(res.data[i].expendMoney.toFixed(2))
          lastData.consumeNumber += Number(res.data[i].consumeNumber.toFixed(2))
          lastData.memberRechargeGetMoney = +(Number(lastData.memberRechargeGetMoney).toFixed(2))
          lastData.realMoney = +(Number(lastData.realMoney).toFixed(2))
          lastData.handselMoney = +(Number(lastData.handselMoney).toFixed(2))
          // lastData.rechargeNumber = Number(lastData.rechargeNumber).toFixed(2)
          lastData.expendMoney = +(Number(lastData.expendMoney).toFixed(2))
          // lastData.consumeNumber = Number(lastData.consumeNumber).toFixed(2)
        }
        res.data.push(lastData)
        for(let i = 0;i<res.data.length-1;i++){
          if(res.data[i].expendMoney)
          console.log(res.data[i])
          expendMoney.value += Number(res.data[i].expendMoney)
          realMoney.value += Number(res.data[i].realMoney)
          handselMoney.value += Number(res.data[i].handselMoney)
          memberRechargeGetMoney.value += Number(res.data[i].memberRechargeGetMoney)
        }
        dataOne.value = res.data
      } catch(err) {
        console.log(err)
      }
    }
    infoData()
    return{
      reqYear,
      changeReqYear,
      dataOne,
      headerStyle,
      cellStyle,
      objectSpanMethod,
      clickFun,
      headerShopName,
      memberRechargeGetMoney,
      handselMoney,
      expendMoney,
      realMoney,
    }
  }
}
</script>
<style scoped>
.vip-type-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #D9EFFF;
  height: 65px;
  margin-bottom: 10px;
}
.vip-type-list>span{
  display: flex;
  background-color: #D9EFFF;
  height: 65px;
  line-height: 65px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: 600;
}


.date-box{
    width: 274px;
    height: 32px;
    margin-right: 20px;
    background: inherit;
    background-color: rgba(255, 255, 255, 1);
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(228, 228, 228, 1);
    border-radius: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.box-left{
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  border-right: 1px solid #b4b4b4;
  font-size: 16px;
  line-height: 25px;
  cursor: pointer;
}
.box-right{
  text-align: right;
  width: 30px;
  height: 25px;
  margin: 2.5px 0;
  font-size: 16px;
  line-height: 25px;
  border-left: 1px solid #b4b4b4;
  cursor: pointer;
}
</style>